<template>
	<div class="sc_view" ref="sc">
		<div class="sc_con">
			<slot />
		</div>
		<slot name="popup"/>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				myScroll: null
			};
		},
		methods: {
			scrollToTop() {
				this.myScroll.scrollTo(0, 0)
			},
			scrollToBottom(y){
				const height = document.querySelector('.sc_view').clientHeight;
				this.myScroll.scrollTo(0,-y + height);
			},
			//解决部分iOS、android4.4+机型不能触发click事件的问题
			iScrollClick() {
				if (/iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent)) return false;
				if (/Chrome/i.test(navigator.userAgent)) return (/Android/i.test(navigator.userAgent));
				if (/Silk/i.test(navigator.userAgent)) return false;
				if (/Android/i.test(navigator.userAgent)) {
					var s = navigator.userAgent.substr(navigator.userAgent.indexOf("Android") + 8, 3);
					return parseFloat(s[0] + s[3]) < 44 ? false : true
				}
			}
		},
		mounted() {
			this.myScroll = new IScroll(this.$refs.sc, {
				tap: true,
				click: this.iScrollClick(),
				probeType: 3,
			});
			this.myScroll.on("beforeScrollStart", () => {
				//下拉刷新
				this.myScroll.refresh();
				this.$emit("refresh")
			});
			this.myScroll.on("scrollEnd", () => {
				//触底加载
				if (this.myScroll.y <= this.myScroll.maxScrollY + 600) {
					this.$emit("loadMore")
				}
				if(this.myScroll.y == 0){
					this.$emit("pullRefresh")
				}
			});
		},
	};
</script>

<style scoped>
	.sc_view {
		overflow: hidden;
	}
</style>
